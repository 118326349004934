'use client';
import React, { ReactNode, useEffect, useState } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ldReactClientId } from 'helpers/utils/environment';

const LDProviderWrapper = ({ children }: { children: ReactNode }) => {
  const [LDProvider, setLDProvider] = useState<any | null | undefined>(null);

  useEffect(() => {
    const ldClientId = ldReactClientId() ?? '';
    asyncWithLDProvider({
      clientSideID: ldClientId,
      reactOptions: {
        useCamelCaseFlagKeys: false,
      },
      context: {
        kind: 'user',
        key: 'user',
        name: 'Padi LD User',
      },
    }).then((provider) => {
      setLDProvider(() => provider);
    });
  }, []);

  if (!LDProvider) {
    return <></>;
  }

  return <LDProvider>{children}</LDProvider>;
};

export default LDProviderWrapper;
