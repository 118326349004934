'use client';

import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

const InitDataDog = () => {
  useEffect(() => {
    const initDD = () => {
      const env =
        process.env.NEXT_PUBLIC_PAYPAL_ENV === 'sandbox'
          ? window.location.hostname.includes('padi.com')
            ? 'stage'
            : 'local'
          : 'prod';
      datadogLogs.init({
        env,
        site: 'datadoghq.com',
        clientToken: 'pub4d25321d9dc52ac5a25c27feb2d9890b',
        datacenter: 'us',
        // show log on the localhost
        // forwardConsoleLogs: ["error", "info"],
        forwardErrorsToLogs: !window.location.hostname.includes('localhost'),
        sessionSampleRate: 100,
        service: 'ct-frontend',
      });
    };
    initDD();
  }, []);

  return <></>;
};

export default InitDataDog;
